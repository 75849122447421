@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600;700&display=swap);
*,
.faq_sec .accordion,
.sub_sec .input-group,
body {
    margin: 0;
    padding: 0
}
a,
button,
h1,
h2,
h3,
h4,
h5,
h6,
input,
select,
textarea {
    outline: 0 !important
}

.global_title,
.top_btn {
    position: relative;
    font-weight: 700;
    text-transform: uppercase
}

.btns,
.top_btn,
.top_btn span {
    display: inline-block
}

.btns,
.top_btn.blue span {
    clip-path: polygon(0 0, 93% 0, 100% 30%, 100% 100%, 7% 100%, 0 70%)
}

.btns,
.global_title,
.table thead th,
.top_btn {
    font-weight: 700
}

.copyright_sec .copyright strong,
.faq_sec .card-header .btn-link,
.footer_sec .contact_phone small,
.footer_sec h3,
.global_title,
.nexperience_sec h3,
.nlearn_block h3,
.nquestion_sec h2,
.nvod_sec .vod_box span,
.table thead th,
.top_btn,
ul.user_list li button {
    text-transform: uppercase
}

.list_info,
li {
    list-style: none
}

.footer_sec h3,
.learn_block h3,
body {
    font-family: Gelion
}

body {
    font-size: 14px;
    color: #fff;
    background: #1d1e22
}

.global_title,
h1,
h2 {
    font-family: Oswald, sans-serif
}

a {
    text-decoration: none;
    transition: .4s ease-in-out
}

.btn:focus,
a:focus,
a:hover,
btn.focus,
button:focus,
select:focus {
    outline: 0;
    text-decoration: none;
    box-shadow: none
}

img {
    border: none;
    vertical-align: middle;
    max-width: 100%
}

.container {
    width: 96%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0
}

.container.sm {
    max-width: 1300px
}

.nquestion_sec,
.ntoday_sec .container {
    max-width: 1440px
}

.product-form__submit,
.shopify-payment-button__button,
p {
    font-size: 20px
}

h2 p {
    font-size: inherit
}

.btns {
    margin: 0;
    padding: 15px 35px;
    font-size: 25px
}

.grdn_btn {
    color: #000;
    background: #efca48 !important;
    background: linear-gradient(to right, #efca48 0, #eecb49 1%, #f0cd4d 1%, #edcf4b 3%, #efd051 4%, #f1d253 6%, #f4d256 6%, #f1d359 8%, #f4d85d 9%, #f4d85d 10%, #f3d960 10%, #f0db5e 11%, #f3db5f 12%, #f4de66 13%, #f6e269 15%, #f5e168 16%, #fbe671 18%, #f7e871 20%, #faeb76 21%, #faeb74 22%, #f9ed77 22%, #faee76 23%, #fcf07a 23%, #fbee7b 24%, #fcf27f 25%, #fcf27f 26%, #fcf781 28%, #fef785 28%, #fff987 31%, #fcf583 33%, #fdf684 33%, #fef37f 34%, #fff280 34%, #fcf07a 36%, #fbee7b 37%, #f7eb73 38%, #f9ea75 38%, #f8e86f 39%, #f8e771 39%, #f7e76d 40%, #f9e66d 41%, #f4e46a 41%, #f4e46a 42%, #f0d85a 47%, #f0d554 49%, #ecd450 50%, #eed04c 51%, #edcf4d 52%, #e9cb45 54%, #eaca43 55%, #eac43d 57%, #e6c23a 59%, #e6bf34 59%, #e6bd31 61%, #e3b82b 62%, #e4b728 64%, #deb01d 67%, #dbad19 69%, #deac19 70%, #deaf1b 72%, #e1b423 73%, #e1b521 73%, #e0b626 74%, #e6bb31 76%, #e6c135 79%, #e7c23d 80%, #eace49 83%, #eccd4e 84%, #edcf4d 85%, #edd153 85%, #ebd657 87%, #efd858 87%, #f1d95f 88%, #f0db5e 89%, #f2df67 91%, #f2e46a 93%, #f6e976 95%, #f5e875 95%, #f6ed78 96%, #f7ec78 97%, #fcf281 98%, #f9f381 99%, #fef485 100%, #fcf487 100%) !important
}

.grdn_btn:hover {
    color: #000;
    background: #fcf487 !important;
    background: linear-gradient(to right, #fcf487 0, #fef485 0, #f9f381 1%, #fcf281 2%, #f7ec78 3%, #f6ed78 4%, #f5e875 5%, #f6e976 5%, #f2e46a 7%, #f2df67 9%, #f0db5e 11%, #f1d95f 12%, #efd858 13%, #ebd657 13%, #edd153 15%, #edcf4d 15%, #eccd4e 16%, #eace49 17%, #e7c23d 20%, #e6c135 21%, #e6bb31 24%, #e0b626 26%, #e1b521 27%, #e1b423 27%, #deaf1b 28%, #deac19 30%, #dbad19 31%, #deb01d 33%, #e4b728 36%, #e3b82b 38%, #e6bd31 39%, #e6bf34 41%, #e6c23a 41%, #eac43d 43%, #eaca43 45%, #e9cb45 46%, #edcf4d 48%, #eed04c 49%, #ecd450 50%, #f0d554 51%, #f0d85a 53%, #f4e46a 58%, #f4e46a 59%, #f9e66d 59%, #f7e76d 60%, #f8e771 61%, #f8e86f 61%, #f9ea75 62%, #f7eb73 62%, #fbee7b 63%, #fcf07a 64%, #fff280 66%, #fef37f 66%, #fdf684 67%, #fcf583 67%, #fff987 69%, #fef785 72%, #fcf781 72%, #fcf27f 74%, #fcf27f 75%, #fbee7b 76%, #fcf07a 77%, #faee76 77%, #f9ed77 78%, #faeb74 78%, #faeb76 79%, #f7e871 80%, #fbe671 82%, #f5e168 84%, #f6e269 85%, #f4de66 87%, #f3db5f 88%, #f0db5e 89%, #f3d960 90%, #f4d85d 90%, #f4d85d 91%, #f1d359 92%, #f4d256 94%, #f1d253 94%, #efd051 96%, #edcf4b 97%, #f0cd4d 99%, #eecb49 99%, #efca48 100%) !important
}

@font-face {
    font-family: Gelion;
    src: url('Gelion-Light.eot');
    src: url('Gelion-Light.eot?#iefix') format('embedded-opentype'), url('Gelion-Light.woff2') format('woff2'), url('Gelion-Light.woff') format('woff'), url('Gelion-Light.ttf') format('truetype'), url('Gelion-Light.svg#Gelion-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: Gelion;
    src: url('Gelion-Regular.eot');
    src: url('Gelion-Regular.eot?#iefix') format('embedded-opentype'), url('Gelion-Regular.woff2') format('woff2'), url('Gelion-Regular.woff') format('woff'), url('Gelion-Regular.ttf') format('truetype'), url('Gelion-Regular.svg#Gelion-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: Gelion;
    src: url('Gelion-Medium.eot');
    src: url('Gelion-Medium.eot?#iefix') format('embedded-opentype'), url('Gelion-Medium.woff2') format('woff2'), url('Gelion-Medium.woff') format('woff'), url('Gelion-Medium.ttf') format('truetype'), url('Gelion-Medium.svg#Gelion-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: 'Gelion Semi';
    src: url('Gelion-SemiBold.eot');
    src: url('Gelion-SemiBold.eot?#iefix') format('embedded-opentype'), url('Gelion-SemiBold.woff2') format('woff2'), url('Gelion-SemiBold.woff') format('woff'), url('Gelion-SemiBold.ttf') format('truetype'), url('Gelion-SemiBold.svg#Gelion-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: Gelion;
    src: url('Gelion-Bold.eot');
    src: url('Gelion-Bold.eot?#iefix') format('embedded-opentype'), url('Gelion-Bold.woff2') format('woff2'), url('Gelion-Bold.woff') format('woff'), url('Gelion-Bold.ttf') format('truetype'), url('Gelion-Bold.svg#Gelion-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: Gelion;
    src: url('Gelion-Black.eot');
    src: url('Gelion-Black.eot?#iefix') format('embedded-opentype'), url('Gelion-Black.woff2') format('woff2'), url('Gelion-Black.woff') format('woff'), url('Gelion-Black.ttf') format('truetype'), url('Gelion-Black.svg#Gelion-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap
}

.global_title {
    margin: 0 0 50px;
    padding: 0;
    font-size: 60px;
    color: #f2ad3d;
    text-align: center
}

.customer h1,
.footer_sec .contact_email a:hover,
.global_title.white,
.login a,
.main-page-title,
.nhero_sec h1 span,
.praash-label,
.proof .detail a:hover,
.proof p span,
.spg-price {
    color: #fff
}

.global_title.left {
    text-align: left
}

.contact-btn,
.copyright_sec,
.nlearn_sec,
.ntoday_sec,
.nvod_sec .vod_box,
.nvod_sec p,
.proof_sec .info,
.sub_sec h2,
.sub_sec h3,
.table tbody td,
.table thead th,
.table-p,
.top_btn span {
    text-align: center
}

.global_title.left.curve:before {
    left: 0;
    transform: inherit
}

.global_title.small {
    font-size: 70px;
    margin-bottom: 15px
}

.global_title.curve {
    padding-bottom: 15px;
    margin-bottom: 35px
}

.global_title.curve:before {
    content: "";
    width: 245px;
    height: 4px;
    background: rgba(255, 255, 255, .3);
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%)
}

header {
    width: 100%;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999
}

.nav_sec {
    width: 100%;
    padding: 21px 0;
    margin: 0
}

.nav_sec .navbar-brand {
    width: 273px;
    margin: 0;
    padding: 0
}

.top_btn::after,
.top_btn::before {
    content: "";
    width: 52px;
    height: 52px;
    transition: .4s ease-in-out
}

.nav_sec .navbar-brand img {
    width: 100%;
    transition: .4s ease-in-out
}

.nav_sec .navbar {
    padding: 0
}

.nav_sec .navbar-light .navbar-nav li {
    margin: 0 80px 0 0;
    padding: 0
}

.nav_sec .navbar-light .navbar-nav .nav-link {
    padding: 10px 0;
    font-size: 26px;
    font-weight: 500;
    font-family: Oswald, sans-serif;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #fff
}

.nav_sec .navbar-light .navbar-nav .active>.nav-link,
.nav_sec .navbar-light .navbar-nav .nav-link.active,
.nav_sec .navbar-light .navbar-nav .nav-link.show,
.nav_sec .navbar-light .navbar-nav .nav-link:focus,
.nav_sec .navbar-light .navbar-nav .nav-link:hover,
.nav_sec .navbar-light .navbar-nav .show>.nav-link {
    color: #f2ad3d;
    border-color: #f2ad3d
}

.top_btn {
    margin: 5px
}

.top_btn span {
    padding: 17px 35px;
    font-size: 24px;
    color: #fff;
    background: #f2ad3d;
    clip-path: polygon(0 0, 90% 0, 100% 28%, 100% 100%, 11% 100%, 0 70%);
    transition: .4s ease-in-out
}

.top_btn::before {
    background: url(btn-curve-bl52x.png) center center/100% no-repeat;
    left: -5px;
    bottom: -5px;
    position: absolute
}

.top_btn::after {
    background: url(btn-curve-tr52x.png) center center/100% no-repeat;
    right: -5px;
    top: -5px;
    position: absolute
}

.nquestion_sec .top_btn:hover span,
.top_btn:hover span {
    background: #fff;
    color: #000
}

.top_btn.blue span {
    background: #04146c
}

.top_btn.blue:hover span {
    background: #fff
}

.nav_sec .dropdown-menu {
    margin-top: 0;
    border-radius: 0;
    box-shadow: 0 0 12px rgba(0, 0, 0, .1);
    border: none
}

.nav_sec .dropdown-item {
    padding: 7px 20px;
    font-size: 15px
}

.nav_sec .dropdown-item.active,
.nav_sec .dropdown-item:hover {
    background: #6c150f;
    color: #fff
}

.nexperience_sec .experience_box::after,
.nexperience_sec .experience_box::before {
    content: "";
    width: 130px;
    height: 130px;
    background: url(experience_curve.png) center center/100% no-repeat;
    transition: .4s ease-in-out
}

section {
    width: 100%;
    padding: 0;
    margin: 0
}

ul.user_list {
    margin: 0 0 40px;
    padding: 0;
    display: flex;
    gap: 10px;
    justify-content: center
}

.learn-best-sec figure,
ul.user_list li {
    margin: 0
}

ul.user_list li button {
    padding: 9px 30px;
    display: flex;
    background: rgba(2, 0, 43, .5);
    gap: 10px;
    font-size: 22px;
    font-weight: 500;
    align-items: center;
    border: none;
    color: #fff
}

ul.user_list li button img {
    width: 25px
}

ul.user_list li button.active {
    background: #02002b
}

ul.day_list {
    margin: 40px 0 0;
    padding: 0;
    display: flex;
    gap: 10px
}

ul.day_list li {
    margin: 0;
    padding: 0;
    display: inline-block
}

ul.day_list li a {
    margin: 0;
    padding: 5px 15px;
    display: inline-block;
    font-size: 24px;
    color: #fff;
    border: 1px solid rgba(255, 255, 255, .4)
}

ul.day_list li a:hover {
    background: #f2ad3d;
    color: #fff
}

.nlearn_sec {
    margin: 0;
    padding: 100px 0 50px;
    clear: both
}

.nlearn_block {
    margin: 0 0 30px;
    padding: 0
}

.nlearn_block figure {
    margin: 0 0 -37px;
    padding: 0
}

.nlearn_block h3 {
    margin: 0 0 20px;
    padding: 0;
    font-size: 32px;
    line-height: 35px;
    font-weight: 500
}

.nlearn_block p {
    margin: 0;
    padding: 0;
    font-size: 21px;
    line-height: 35px;
    letter-spacing: 1px
}

.ntoday_sec {
    margin: 0;
    padding: 50px 0
}

.ntoday_sec .global_title {
    text-shadow: 0 0 40px #000
}

.ntoday_sec p {
    margin: 0 0 50px;
    padding: 0;
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 1px
}

.ntoday_sec .inner {
    margin: 0 0 40px;
    padding: 0;
    display: flex;
    justify-content: center
}

.ntoday_sec .today_video {
    position: relative
}

.ntoday_sec .today_video iframe {
    width: 1080px
}

.subs_block {
    margin: 0;
    padding: 10px;
    background: #fff;
    display: flex;
    clip-path: polygon(0 0, 96% 0, 100% 28%, 100% 100%, 3% 100%, 0 80%)
}

.subs_block .field {
    margin: 0;
    padding: 0 30px;
    width: 100%;
    font-size: 20px;
    color: #666;
    border: none
}

.subs_block button.top_btn {
    white-space: nowrap;
    border: none;
    background: 0 0;
    margin: 0
}

.subs_block button.top_btn:hover span {
    background: #333;
    color: #fff
}

.footer_sec {
    margin: 0;
    padding: 110px 0 0;
    border-top: 1px solid #414141;
    background: #111;
    clear: both
}

.footer_sec.training {
    padding-top: 360px
}

.footer_sec.dark {
    background: #111;
    box-shadow: 0 -10px 70px rgba(5, 0, 88, .3)
}

.footer_sec .ftr_logo {
    margin: 0 0 50px;
    padding: 0
}

ul.social_ic {
    margin: 0;
    padding: 0;
    gap: 15px
}

ul.social_ic li {
    margin: 7px;
    padding: 0;
    display: inline-block
}

ul.social_ic li a {
    padding: 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    border: 1px solid #6e6e6e;
    font-size: 22px;
    text-decoration: none;
    color: #f2ad3d
}

ul.social_ic li a img {
    transition: .4s ease-in-out
}

ul.social_ic li a:hover {
    background: #fff;
    border-color: #fff
}

ul.social_ic li a:hover img {
    filter: brightness(0) invert(0)
}

.footer_sec h3 {
    margin: 0 0 32px;
    padding: 0;
    font-size: 26px;
    font-weight: 500
}

.footer_sec ul.ftr_menu {
    margin: 0;
    padding: 0;
    columns: 2
}

.footer_sec ul.ftr_menu li {
    margin: 0 0 12px;
    padding: 0;
    list-style: none;
    font-size: 24px
}

.footer_sec ul.ftr_menu li a {
    color: #ccc
}

.footer_sec .contact_email a,
.footer_sec ul.ftr_menu li a:hover,
.nnext_sec h3 span {
    color: #f2ad3d
}

.footer_sec .contact_phone {
    margin: 0;
    padding: 0;
    font-size: 30px;
    color: #fff;
    font-weight: 600
}

.footer_sec .contact_phone small {
    font-size: 18px;
    color: #fff;
    display: block;
    font-weight: 400
}

.footer_sec .contact_email {
    margin: 0;
    padding: 0;
    font-size: 24px
}

.footer_sec .contact_email small {
    font-size: 18px;
    color: #fff;
    display: block
}

.copyright_sec {
    margin: 100px 0 0;
    padding: 55px 0 50px;
    border-top: 1px solid #414141
}

.copyright_sec .copyright {
    margin: 0 0 20px;
    padding: 0;
    font-size: 20px;
    color: #aaa
}

.copyright_sec .copyright strong {
    font-weight: 500;
    color: #fff
}

.copyright_sec p {
    margin: 0 auto;
    padding: 0;
    font-size: 20px;
    color: #aaa;
    max-width: 1160px
}

.nquestion_sec {
    margin: 0;
    padding: 100px;
    background: url(question_bg.png) center center/cover no-repeat;
    position: absolute;
    bottom: -250px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%
}

.nquestion_sec h2 {
    margin: 0 0 60px;
    padding: 0;
    font-size: 80px;
    color: #fff;
    text-shadow: 5px 0 5px rgba(5, 0, 88, .4)
}

.nquestion_sec .top_btn span {
    background: #04146c;
    clip-path: polygon(0 0, 91% 0, 100% 28%, 100% 100%, 9% 100%, 0 70%)
}

.nvod_sec .vod_box span,
.what_sec ul.what_srv li .day_info span {
    clip-path: polygon(0 0, 100% 0, 90% 50%, 100% 100%, 0 100%, 10% 50%);
    position: absolute;
    top: -14px;
    display: inline-block
}

.nvod_sec {
    margin: 0;
    padding: 155px 0 140px;
    background: url(vod_bg.jpg) center center/cover no-repeat;
    position: relative;
    clear: both
}

.nvod_sec .curve_left {
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: -30px;
    left: 0
}

.nvod_sec .curve_right {
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: -30px;
    right: 0
}

.nvod_sec .global_title {
    margin-bottom: 30px;
    color: #fff
}

.nvod_sec p {
    margin: 0 0 85px;
    padding: 0;
    font-size: 21px;
    line-height: 35px
}

.nvod_sec .grid {
    padding: 0 40px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 40px
}

.nvod_sec .vod_box {
    margin: 0;
    padding: 55px 15px 35px;
    border: 1px solid #404040;
    background: #000;
    position: relative
}

.nvod_sec .vod_box span {
    margin: 0;
    padding: 0 19px;
    font-size: 19px;
    font-weight: 500;
    background: #04146c;
    left: 50%;
    transform: translate(-50%, 0);
    white-space: nowrap
}

.nvod_sec .vod_box figure {
    margin: 0 0 15px;
    padding: 0
}

.nvod_sec .vod_box h3 {
    margin: 0;
    padding: 0;
    font-size: 22px;
    font-weight: 500
}

.table tr {
    border-left: 1px solid #2e2f33;
    clip-path: polygon(98% 0, 100% 35%, 100% 100%, 2% 100%, 0 65%, 0 0)
}

.table thead th {
    padding: 23px 5px;
    font-size: 22px;
    color: #fff;
    background: #570a70;
    border: none;
    border-bottom: none;
    position: relative
}

.table thead th::before {
    content: "";
    width: 1px;
    height: 80px;
    background: #793b8d;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9
}

.table thead th:nth-child(7) {
    border-right: none
}

.table tbody td {
    padding: 30px;
    font-size: 21px;
    line-height: 35px;
    color: #fff;
    border: none;
    border-bottom: 1px solid #2e2f33;
    border-right: 1px solid #2e2f33
}

.nexperience_sec {
    margin: 0;
    padding: 140px 0 160px
}

.nexperience_sec .row>div {
    padding-left: 25px;
    padding-right: 25px
}

.nexperience_sec .experience_box {
    margin: 0 0 25px;
    padding: 0;
    position: relative;
    clip-path: polygon(0 0, 92.5% 0, 100% 13.5%, 100% 100%, 9% 100%, 0 84.5%)
}

.nexperience_sec .experience_box::after {
    position: absolute;
    top: 0;
    right: 0
}

.nexperience_sec .experience_box::before {
    position: absolute;
    bottom: 7px;
    left: 0;
    transform: rotate(180deg)
}

.nexperience_sec h3 {
    margin: 0;
    padding: 0;
    font-size: 26px;
    font-weight: 600
}

.ncontact_us_sec {
    margin: 0;
    padding: 0 0 280px;
    position: relative;
    clear: both
}

.faq_sec {
    width: 100%;
    float: left;
    margin: 0;
    padding: 140px 0;
    position: relative;
    z-index: 5;
    background: #000
}

.faq_sec .card {
    margin: 0 0 20px;
    padding: 0;
    background: 0 0;
    border: 2px solid #181818 !important;
    border-radius: 7px !important;
    -webkit-transition: .4s ease-in-out;
    -moz-transition: .4s ease-in-out;
    -o-transition: .4s ease-in-out;
    -ms-transition: .4s ease-in-out;
    transition: .4s ease-in-out
}

.faq_sec .card.active {
    background: #111 !important;
    border-color: #111 !important
}

.faq_sec .card-header {
    margin: 0;
    padding: 0;
    background: 0 0;
    border: none;
    border-radius: 0
}

.faq_sec .card-header .btn-link {
    width: 100%;
    float: left;
    margin: 0;
    padding: 22px 70px 22px 35px;
    background: 0 0;
    border: none;
    border-radius: 0;
    text-align: left;
    font-size: 28px;
    color: #fff;
    font-weight: 500;
    text-decoration: none;
    position: relative;
    font-family: Gelion
}

.faq_sec .card-header .btn-link:after {
    content: "\f106";
    font-family: FontAwesome;
    font-size: 40px;
    color: #fff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 30px
}

.faq_sec .card-header .btn-link.collapsed:after {
    content: "\f107";
    color: #8e8e90
}

.faq_sec .card-body {
    padding: 0 35px 30px
}

.faq_sec p {
    margin: 0;
    padding: 0;
    font-size: 22px;
    color: #fff;
    font-weight: 300
}

.sub_sec h2,
.sub_sec h3,
.what_sec ul.what_srv li .day_info span {
    color: #000;
    font-weight: 700;
    text-transform: uppercase
}

.sub_sec {
    width: 100%;
    float: left;
    margin: 0;
    padding: 90px 0 100px;
    background: url(subs_bg.jpg) center center/cover no-repeat
}

.sub_sec .container {
    max-width: 919px
}

.sub_sec h3 {
    margin: 0;
    padding: 0;
    font-size: 30px
}

.sub_sec h2 {
    margin: 0 0 40px;
    padding: 0;
    font-size: 75px
}

.sub_sec .input-group i {
    position: absolute;
    left: 30px;
    top: 47%;
    transform: translateY(-50%);
    z-index: 6
}

.sub_sec .input-group .form-control {
    margin: 0;
    padding: 0 30px 0 60px;
    font-size: 21px;
    color: #000;
    position: relative;
    z-index: 5;
    height: 84px;
    border-radius: 0;
    border: none;
    box-shadow: none
}

.sub_sec .input-group .btns {
    margin: 0 0 0 20px;
    border-radius: 0;
    border: none;
    height: 84px;
    box-shadow: none
}

.valorant_sec {
    background: url(hero_image.png) center center/cover no-repeat
}

.valorant_sec p {
    margin: 0 0 30px
}

.valorant_sec small {
    font-size: 22px;
    line-height: 35px;
    color: #fff;
    margin: 0;
    padding: 0;
    font-weight: 300
}

.list_info li,
.what_sec p {
    font-weight: 500;
    color: #fff
}

.what_sec {
    width: 100%;
    float: left;
    margin: 0;
    padding: 170px 0 120px;
    background: url(what_bg.jpg) center center/cover no-repeat;
    position: relative
}

.faq_inner_page,
.proof {
    background: #101010
}

.what_sec .global_title {
    margin-bottom: 60px
}

.what_sec ul.what_srv {
    width: 100%;
    float: left;
    margin: 0;
    padding: 0 15px;
    position: relative;
    z-index: 1
}

.what_sec ul.what_srv li {
    width: 20%;
    float: left;
    margin: 0 0 40px;
    padding: 0 15px;
    text-align: center
}

.what_sec ul.what_srv li .day_info {
    width: 100%;
    float: left;
    margin: 18px 0 0;
    background: #000;
    color: #fff;
    border: 1px solid #404040;
    padding: 50px 0 35px;
    position: relative
}

.proof figure,
.proof figure img {
    border-radius: 50%
}

.what_sec ul.what_srv li .day_info span {
    width: 96px;
    height: 27px;
    line-height: 27px;
    font-size: 18px;
    background: url(day_curve.jpg) center center/cover no-repeat;
    left: 0;
    right: 0;
    margin: auto;
    width: fit-content;
    padding: 0 20px;
    max-width: 100%
}

.what_sec p {
    margin: 0;
    padding: 0;
    font-size: 22px;
    line-height: 23px;
    min-height: 46px
}

.welcome_sec {
    width: 100%;
    float: left;
    padding: 140px 0 50px;
    margin: 0
}

.welcome_sec figure {
    padding: 0;
    margin: 0 0 0 0%
}

.welcome_sec .detail {
    width: 100%;
    float: right;
    padding: 10px 0 0;
    margin: 0;
    max-width: 550px
}

.welcome_sec .list_info {
    width: 100%;
    max-width: 510px
}

.list_info {
    margin: 0;
    padding: 0
}

.list_info li {
    margin: 0 0 27px;
    padding: 1px 0 0 40px;
    font-size: 23px;
    line-height: 25px;
    position: relative;
    background: url(check_icon.png) left top no-repeat
}

.proof_sec {
    width: 100%;
    float: left;
    margin: 0;
    padding: 180px 0 190px
}

.proof_sec .info {
    margin: 0 0 40px;
    padding: 0
}

.proof_sec .info p {
    margin: 0;
    padding: 0;
    font-size: 22px;
    color: #fff;
    line-height: 29px
}

.proof {
    width: 100%;
    float: left;
    margin: 0;
    padding: 30px;
    position: relative
}

.proof .author_info {
    width: 100%;
    float: left;
    margin: 0 0 30px;
    padding: 0 20px 0 0;
    position: relative
}

.proof figure {
    width: 75px;
    float: left;
    margin: 0;
    padding: 0
}

.proof .detail {
    overflow: hidden;
    margin: 0;
    padding: 18px 0 0 15px;
    font-size: 18px;
    color: #646464;
    line-height: 24px
}

.proof .detail strong {
    margin: 0;
    padding: 0;
    font-size: 22px;
    font-weight: 600;
    color: #fff
}

.proof .detail a {
    color: #646464
}

.proof .faceb_link {
    margin: 0;
    padding: 0;
    font-size: 30px;
    color: #fff;
    opacity: .5;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%)
}

.proof .faceb_link:hover {
    opacity: 1
}

.proof p {
    width: 100%;
    display: inline-block;
    margin: 0;
    padding: 0;
    clear: both;
    font-size: 20px;
    line-height: 29px;
    font-weight: 300;
    color: #8c8c8c
}

.faq_inner_page .card {
    border: 2px solid #272727 !important
}

.faq_inner_page .card.active {
    border-color: #000 !important;
    background: #000 !important
}

.what_sec2 {
    padding: 170px 0
}

.learn_block {
    width: 100%;
    float: left;
    padding: 50px 30px 30px;
    margin: 0 0 30px;
    background: url(dota_box_bg.jpg) center center/cover no-repeat
}

.learn_block h3 {
    padding: 18px 0 18px 30px;
    margin: 0 0 11px -30px;
    font-size: 22px;
    color: #000;
    line-height: 30px;
    font-weight: 700;
    background: url(bg-arrow-img.png) center center/cover no-repeat;
    text-transform: uppercase;
    width: 190px;
    float: left
}

.learn_block p {
    font-size: 22px;
    color: #000;
    font-weight: 400;
    line-height: 1.5;
    clear: both
}

.learn_block img {
    padding: 9px 0 0 10px
}

.what_sec2 .row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap
}

.what_sec2 .row>[class*=col-] {
    display: flex
}

.learn_block2 {
    margin-bottom: -20px;
    margin-top: 50px
}

.video-container {
    overflow: hidden;
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: 0 auto
}

.video-container::after {
    padding-top: 56.25%;
    display: block;
    content: ''
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.pro_gold_plan {
    background: url(pro_gold_plan_bg.jpg) center center/cover no-repeat
}

.pro_gold_plan h3 {
    color: #000;
    line-height: 30px
}

.pro_gold_plan h2 {
    color: #000
}

.pro_gold_plan h2 small {
    color: rgba(0, 0, 0, .5)
}

.pro_gold_plan .list_info li {
    color: #000;
    background: url(check_icon_2.png) left top no-repeat
}

.pro_gold_plan p {
    font-size: 19px;
    color: #000;
    font-weight: 300
}

.pro_gold_plan .black_btn {
    color: #fff;
    background: #000 !important
}

.pro_gold_plan .black_btn:hover {
    color: #000;
    background: #fcf487 !important
}

@media (max-width:1400px) {
    .what_sec2 {
        padding: 100px 0
    }
}

@media (max-width:1366px) {
    .learn_block h3 {
        padding: 13px 0 13px 30px;
        width: 160px;
        font-size: 20px
    }
    .learn_block img {
        padding: 5px 0 0 10px
    }
}

@media (max-width:1200px) {
    .star:after {
        content: "";
        width: 65px;
        height: 65px;
        left: -25px;
        top: -16px
    }
    .star.down:after {
        bottom: -16px;
        right: -30px
    }
    .learn_block {
        padding: 0 30px 45px
    }
    .learn_block h3 {
        padding: 7px 0 7px 20px;
        width: 130px;
        font-size: 16px
    }
    .learn_block img {
        max-width: 45px
    }
    .learn_block p {
        line-height: 1.5 !important
    }
}

@media (max-width:991px) {
    .star:after {
        content: "";
        width: 65px;
        height: 65px;
        left: -26px;
        top: -19px
    }
    .star.down:after {
        bottom: -21px;
        right: -28px
    }
    .learn_block {
        padding: 0 30px 30px
    }
    .learn_block h3 {
        padding: 18px 0 18px 30px;
        margin: 0 0 11px -30px;
        font-size: 22px;
        width: 190px
    }
    .learn_block img {
        max-width: 100%;
        padding: 9px 0 0 10px
    }
    .learn_block2 {
        margin-bottom: auto;
        margin-top: 0
    }
}

.customer .field input,
.customer select,
.field__input,
.select__select {
    color: #fff;
    border: .1rem solid #ffffff50
}

.customer .field input::placeholder,
.customer select::placeholder,
.field__input::placeholder,
.select__select::placeholder {
    color: #fff;
    opacity: .6
}

.grecaptcha-badge {
    z-index: 10
}

.login a:hover {
    color: #d9ab4d
}

#shopify-section-template--15403262116063__form {
    margin-top: 0
}

.aaamembership_form {
    padding: 50px 15px
}

h1.header,
h3.header {
    display: block
}

.caption-large,
.caption-with-letter-spacing,
.customer .field input,
.customer select,
.field__input,
.form__label,
.select__select {
    font-size: 18px
}

.rte ul {
    padding-left: 0
}

.praash-per-order-price-label {
    display: block;
    font-size: 16px
}

.learn-best-sec {
    background: #181818
}

.proof_slider {
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden
}

@media (min-width:991px) {
    .learn-best-sec {
        padding-top: 100px
    }
}

.select__select {
    color: #aaa
}

.table-mobile {
    display: none
}

@media (max-width:767px) {
    .learn_block2 {
        margin-bottom: 30px
    }
    .table-responsive {
        display: none
    }
    .table-mobile {
        display: block;
        text-align: center
    }
}

.contact__fields .field__input {
    margin: 0;
    padding: 10px;
    background: #fff;
    clip-path: polygon(0 0, 97% 0, 100% 35%, 100% 100%, 3% 100%, 0 70%)
}

.contact__fields .field .field__input {
    margin: 0;
    padding: 0 30px;
    width: 100%;
    font-size: 20px;
    color: #666;
    border: none;
    height: 60px
}

.textarea-field .field__input {
    margin: 0;
    padding: 15px 30px;
    background: #fff;
    clip-path: polygon(0 0, 98.4% 0, 100% 21%, 100% 100%, 1.6% 100%, 0 81%);
    font-size: 20px;
    font-weight: 400;
    color: #666
}

.textarea-field .field .field__input {
    margin: 0;
    padding: 15px 30px;
    width: 100%;
    font-size: 20px;
    color: #666;
    border: none
}

.contact__fields .field .field__input::placeholder,
.textarea-field .field__input::placeholder {
    color: #666;
    opacity: 1
}

.faq_inner_page .card:has(.show) {
    border: 2px solid #ccc !important
}

.nnext_sec h3 {
    margin: 0 auto 45px;
    padding: 0;
    font-size: 45px;
    color: #fff;
    font-weight: 700;
    max-width: 850px;
    font-family: Oswald, sans-serif
}

.table-p {
    margin: 100px auto 0;
    padding: 0;
    font-size: 21px;
    line-height: 35px;
    letter-spacing: 1px;
    max-width: 900px
}

.nprice_sec,
.npricing_sec {
    clear: both
}

.nsubs_sec h2 {
    text-transform: inherit
}

ul.social_ic li a:hover {
    color: #334fb4
}

.table-p-extra {
    margin: 60px auto 0
}

@media (max-width:991px) {
    ul.social_ic {
        text-align: center;
        display: block
    }
    .table-p-extra {
        margin-top: 50px
    }
}